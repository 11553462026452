import { Image } from '@chakra-ui/image';
import { Box, Flex, Heading } from '@chakra-ui/layout';

export default function NotFound() {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      minHeight="100vh"
      flexDir="column">
      +
      <Box mt={-200} textAlign="center">
        <Image src="/imgs/errors/astronaut.svg" width={500} />
        <Heading textAlign="center" width="100%">
          500 - That's an error
        </Heading>
      </Box>
    </Flex>
  );
}
